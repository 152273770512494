import Axios from "axios";

async function getGraph() {
  let response = await Axios({
    url: "https://graph-api.politigrafo.danilopimentel.com/graph",
    method: "GET",
  });

  return response.data;
}

const graph = { getGraph };

export default graph;
