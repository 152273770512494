import * as d3 from "d3";
import * as React from "react";
import * as reorder from "reorder.js/dist/reorder.esm.js";
import graphUtil from "./graph";
import logo from "./logo.svg";
import search from "./search.svg";
import _ from "lodash";
import { TwitterTweetEmbed } from "react-twitter-embed";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null,
      selectedSearch: "",
      search: "",
      selected: "",
    };
    this.svgRef = React.createRef();
  }

  async componentDidMount() {
    try {
      let data = await graphUtil.getGraph();
      this.setState(
        {
          isLoaded: true,
          data,
        },
        this.renderGraph
      );
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  // https://observablehq.com/@taniki/regionales2021-alliances-arc
  renderGraph = () => {
    let nodes = this.state.data.result.users.map((d) => ({
      id: d.twitter_screen_name,
    }));
    let links = this.state.data.result.mentions.map((d) => ({
      source: d.source_screen_name,
      target: d.target_screen_name,
      value: d.tweet_ids.length,
    }));

    let graph = {
      nodes,
      links,
    };

    let items = graph.nodes.map((n) => n.id);

    let boxSize = 25;
    let labelWidth = 200;
    let margin = {
      top: 20,
      left: 20,
      right: 20,
      bottom: 20,
    };

    let width = window.innerWidth;
    let eachRow = Math.hypot(boxSize, boxSize);
    let chartHeight = eachRow * items.length;
    let height = chartHeight + margin.top + margin.bottom;

    let svg = d3
      .select(this.svgRef.current)
      .attr("width", width)
      .attr("height", height);

    let group = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    let y = d3
      .scaleBand()
      .domain(items)
      .range([0, items.length * eachRow]);

    let listing = group
      .selectAll("g.list-item")
      .data(items)
      .join("g")
      .attr("class", "list-item")
      .attr("id", (d) => d)
      .attr("transform", (d) => `translate(0, ${y(d)})`);

    listing
      .append("text")
      .attr("text-anchor", "end")
      .attr("x", labelWidth - 5)
      .attr("y", eachRow / 2 + 4)
      .text((d) => `@${d}`)
      .on("click", this.onLabelClick);

    listing
      .append("circle")
      .attr("fill", "black")
      .attr("cx", labelWidth + 15)
      .attr("cy", boxSize / 2 + 3)
      .attr("r", boxSize / 2 - 3);

    let radius = (link) => 0.5 * Math.abs(y(link.source) - y(link.target)) + 1;

    let arc = (link) => {
      let r = radius(link);

      return d3
        .arc()
        .innerRadius(r - 1)
        .outerRadius(r + 1)
        .startAngle(0)
        .endAngle(Math.PI)(link);
    };

    let transform = (link) => {
      let x = labelWidth + boxSize;
      let y_ =
        boxSize / 2 + radius(link) + d3.min([y(link.source), y(link.target)]);
      return `translate(${x},${y_})`;
    };

    let mentions = group
      .selectAll("g.links")
      .data(links)
      .join("g")
      .attr("class", "links");

    let opacity = (link) => {
      return (Math.floor(link.value / 3) + 1) * 0.25;
    };

    let arcs = mentions
      .append("path")
      .attr("d", arc)
      .attr("transform", transform)
      .attr("stroke", "black")
      .attr("opacity", opacity);

    svg.style("background-color", "white");

    let n = nodes.length;
    let matrix = Array.from(nodes, (_, i) =>
      d3.range(n).map((j) => ({
        x: j,
        y: i,
        z: 0,
      }))
    );
    let index = nodes.map((d, i) => ("id" in d ? d.id : i));
    let reorderLinks = [];

    nodes.forEach((node) => {
      node.count = 0;
    });

    links.forEach((link) => {
      let i = index.indexOf(link.source),
        j = index.indexOf(link.target);
      if (!("value" in link)) link.value = 1;
      matrix[i][j].z += link.value;
      matrix[j][i].z += link.value;
      matrix[i][j].z += link.value;
      matrix[j][i].z += link.value;
      nodes[i].count += link.value;
      nodes[j].count += link.value;
      reorderLinks.push({
        source: i,
        target: j,
        value: link.value,
      });
    });

    let reorderGraph = reorder.graph().nodes(nodes).links(reorderLinks).init();

    let barycenter = reorder.barycenter_order(reorderGraph);
    let improved = reorder.adjacent_exchange(reorderGraph, ...barycenter);
    improved[0].forEach((lo, i) => (nodes[i].barycenter = lo));

    let permutation = nodes.map((n) => n.barycenter);

    y.domain(permutation.map((i) => graph.nodes[i].id));

    let duration = 100;

    listing
      .transition()
      .duration(duration)
      .attr("transform", (d, i) => `translate(0, ${y(d)})`);

    arcs
      .transition()
      .duration(duration)
      .attr("d", arc)
      .attr("transform", transform);
  };

  onSearchChange = (event) => {
    this.setState({
      selectedSearch: "",
      search: event.target.value,
    });
  };

  removeSelectedSearchClass = () => {
    let currSelected = document.getElementsByClassName("selected-item")[0];
    if (currSelected) {
      currSelected.classList.remove("selected-item");
    }
  };

  onLabelClick = (event) => {
    this.selectName(event.target.__data__);
  };

  selectName = (name) => {
    let element = document.getElementById(name);
    window.scrollTo({
      top: window.scrollY + element.getBoundingClientRect().top - 100,
      behavior: "smooth",
    });

    this.removeSelectedSearchClass();
    element.classList.add("selected-item");

    this.setState({
      search: name,
      selectedSearch: name,
      selected: name,
    });
  };

  renderAutocomplete = () => {
    if (!this.state.search || this.state.search === this.state.selectedSearch) {
      return null;
    }

    let searchTerm = this.state.search.trim();
    if (searchTerm[0] === "@") {
      searchTerm = searchTerm.slice(1);
    }

    let matches = this.state.data.result.users
      .map((d) => d.twitter_screen_name)
      .sort()
      .filter((screen_name) =>
        screen_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

    let items = matches.map((name) => (
      <button
        key={name}
        className="autocomplete-item"
        onClick={() => this.selectName(name)}
      >
        @{name}
      </button>
    ));

    if (!items.length) {
      return null;
    }

    return <div className="autocomplete">{items}</div>;
  };

  renderDetail = () => {
    let { selected } = this.state;
    if (!this.state.data || !selected) {
      return null;
    }

    let related = [];
    let posts = [];

    this.state.data.result.mentions.forEach(
      ({ source_screen_name, target_screen_name, tweet_ids }) => {
        if (source_screen_name === selected) {
          related.push(target_screen_name);
          posts = [...posts, ...tweet_ids];
        } else if (target_screen_name === selected) {
          related.push(source_screen_name);
          posts = [...posts, ...tweet_ids];
        }
      }
    );

    related = _.uniq(related);
    posts = _.uniq(posts);

    return (
      <div className="detail">
        <div className="detail-header">
          <h1 className="detail-title">@{selected}</h1>
          <div>
            <a
              href={"http://twitter.com/" + selected}
              target="_blank"
              rel="noreferrer noopener"
            >
              Ver no Twitter
            </a>
          </div>
        </div>
        <div className="detail-body">
          <div className="detail-section">
            <h2 className="detail-section-title">Relacionados</h2>
            <div className="detail-section-body">
              {related.map((name) => (
                <React.Fragment>
                  <button
                    key={name}
                    onClick={() => this.selectName(name)}
                    className="detail-related-item"
                  >
                    @{name}
                  </button>{" "}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="detail-section">
            <h2 className="detail-section-title">Posts</h2>
            <div className="detail-section-body">
              {posts.map((tweetId) => (
                <TwitterTweetEmbed key={tweetId} tweetId={tweetId} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { error } = this.state;
    if (error) {
      return <div> Error: {error.message} </div>;
    }

    return (
      <div className="container">
        <div className="top-bar">
          <div className="logo">
            <img className="logo-image" src={logo} alt="Politígrafo" />
          </div>
          <div className="search">
            <input
              type="text"
              className="search-input"
              placeholder="Buscar"
              value={this.state.search}
              onChange={this.onSearchChange}
            />
            <img className="search-icon" src={search} alt="Buscar" />

            {this.renderAutocomplete()}
          </div>
        </div>

        <div className="body">
          <div className="visualization">
            <svg className="visualization-svg" ref={this.svgRef}></svg>
          </div>

          {this.renderDetail()}
        </div>
      </div>
    );
  }
}

export default App;
